<template>
  <v-container fluid>
    <v-breadcrumbs :items="breadcrumbs" divider="-"></v-breadcrumbs>
    <v-row wrap align-center>
      <v-col cols="12">
        <v-card>
          <v-overlay v-if="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <div v-else>
            <v-card-title
              ><v-icon :color="pedido.estado.color"
                >mdi-{{ pedido.estado.icono }}</v-icon
              >
              <span :class="`${pedido.estado.color}--text`">{{
                pedido.estado.texto
              }}</span></v-card-title
            >
            <v-card-subtitle>Orden # {{ _id.slice(-5) }} <i>{{$moment(pedido.fechaAlta).format('dddd DD [de] MMMM YYYY H:mm')}} hs</i></v-card-subtitle>

            <v-card-text>
              <v-alert type="info" v-if="pedido.cambiaronPrecios"
                >Se detectó cambios en uno o más productos de este pedido desde
                la ultima vez que lo revisaste. Se actualizaron los valores del
                pedido.</v-alert
              >
              <v-alert type="info" v-if="pedido.estado.val != 'entregado'">
                Retire su pedido por <b>Av Velez Sarfield 1426, Córdoba</b> de
                Lunes a Viernes de 9 a 18 hs, y Sábados de 10 a 14 hs.
              </v-alert>
              <v-alert type="success" v-else>
                Se entregó su pedido el dia <b>{{$moment(pedido.fechaEntrega).format('dddd DD [de] MMMM YYYY H:mm')}} hs</b>.
              </v-alert>
              <v-alert type="success" v-if="pedido.estado.val != 'pendiente_pago'">
                Recibimos su pago el dia <b>{{$moment(pedido.fechaVenta).format('dddd DD [de] MMMM YYYY H:mm')}} hs</b>.
              </v-alert>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        En Stock
                      </th>
                      <th class="text-left">
                        Producto
                      </th>
                      <th class="text-left">
                        Precio Unitario
                      </th>
                      <th class="text-left">
                        Cantidad
                      </th>
                      <th class="text-left">
                        Monto
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in pedido.productos" :key="index">
                      <td>
                        <v-icon :color="item.stock ? 'green' : 'red'"
                          >mdi-{{ item.stock ? "check" : "cancel" }}</v-icon
                        >
                      </td>
                      <td><a @click="$router.push(`/productos/${item.slug}`)">{{ item.nombre }}</a></td>
                      <td>
                        $ {{ item.precio }}
                        <small v-if="item.precio_anterior"
                          >(Anterior: $ {{ item.precio_anterior }})</small
                        >
                      </td>
                      <td>{{ item.cantidad }}</td>
                      <td>$ {{ item.total }}</td>
                    </tr>
                    <tr>
                      <td>Subtotal</td>
                      <td>$ {{ pedido.subtotal }}</td>
                      <td>Envío</td>
                      <td>$ {{ pedido.costoEnvio }}</td>
                    </tr>
                    <tr>
                      <td><b>Total</b></td>
                      <td>
                        <b>$ {{ pedido.total }}</b>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Pedido",
  data: () => ({
    pedido: {},
    loading: true,
    breadcrumbs: [
      {
        text: "Ver Mis Pedidos",
        disabled: false,
        to: "/cuenta",
      },
    ],
  }),
  computed: {
    _id() {
      return this.$route.params._id;
    },
  },
  methods: {
    async _obtenerPedido() {
      this.loading = true;
      try {
        const pedido = await this.axios.get(`/pedido/${this._id}`);
        this.pedido = pedido.data;
        this.loading = false;
      } catch (error) {
        this.$router.push("/cuenta");
      }
    },
  },
  created() {
    this._obtenerPedido();
  },
};
</script>
